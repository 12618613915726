<template>
  <v-card class="order">
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Сохранено</v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4 py-2"
      >
        <span class="headline">Настройки</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4 pt-1"
      >
        <div style="overflow: auto;">
          <v-layout
            row
            nowrap
          >
            <template v-for="(col, colIndex) in settings.orderSettings">
              <v-flex
                px-1
                :key="`col-${colIndex}`"
                class="mb-5"
                style="flex-shrink: 0; flex-basis: 350px; max-width: 350px;"
              >
                <v-layout
                  row
                  wrap
                  align-center
                >
                  <v-flex
                    xs10
                  >
                    <v-text-field
                      label="Название столбца"
                      v-model="col.columnName"
                      hide-details
                      class="mb-2"
                    ></v-text-field>
                  </v-flex>

                  <v-flex
                    xs2
                  >
                    <v-btn
                      small
                      color="error"
                      @click="delCol(colIndex)"
                    >
                      <v-icon dark>delete</v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
                <v-layout
                  row
                  wrap
                  align-center
                >
                  <!-- <v-flex
                    xs8
                  >
                    <v-select
                      label="Свойство"
                      :items="propsList"
                      :rules="[v => !!v || 'Заполните поле']"
                      item-text="name"
                      item-value="code"
                      v-model.number="col.sortField"
                      hide-details
                      class="mb-2"
                    ></v-select>
                  </v-flex>
                  <v-flex
                    xs4
                  >
                    <v-select
                      label="Сортировать"
                      :items="sortOrder"
                      item-text="name"
                      item-value="code"
                      v-model.number="col.sortOrder"
                      hide-details
                      class="mb-2"
                    ></v-select>
                  </v-flex> -->

                  <v-flex
                    xs12
                  >
                    <v-text-field
                      label="Ширина столбца"
                      v-model.number="col.width"
                      hide-details
                      class="mb-4"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <template v-for="(prop, propIndex) in col.dataFields">
                  <v-card
                    :key="`prop-${colIndex}-${propIndex}`"
                    class="mb-2"
                  >
                    <v-card-text>
                      <v-layout
                        row
                        wrap
                        align-center
                      >
                        <v-flex
                          xs10
                        >
                          <v-select
                            label="Свойство"
                            :items="propsList"
                            :rules="[v => !!v || 'Заполните поле']"
                            item-text="name"
                            item-value="code"
                            v-model="prop.field"
                            @change="prop.displayName = propsList.find((item) => item.code === $event).name"
                            hide-details
                            class="mb-4"
                          ></v-select>
                        </v-flex>

                        <v-flex
                          xs2
                        >
                          <v-btn
                            small
                            color="error"
                            @click="delProp(colIndex, propIndex)"
                          >
                            <v-icon dark>delete</v-icon>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <v-text-field
                        label="Название свойства в таблице"
                        v-model="prop.displayName"
                        hide-details
                        class="mb-4"
                      ></v-text-field>
                    </v-card-text>
                  </v-card>
                </template>
                <v-btn
                  fab
                  color="info"
                  @click="addProp(colIndex)"
                >
                  <v-icon dark>add</v-icon>
                </v-btn>
              </v-flex>
            </template>
            <v-flex
              xs2
              px-1
            >
              <v-btn
                fab
                color="info"
                @click="addCol()"
              >
                <v-icon dark>add</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </div>
      </v-card-text>
      <v-card-actions
        class="px-4 py-2"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      settings: {
        // sortField: '',
        // sortOrder: '',
        orderSettings: [],
      },
      propsList: [
        {
          code: 'client_name',
          name: 'Имя клиента',
        },
        {
          code: 'client_phone',
          name: 'Телефон клиента',
        },
        {
          code: 'client_type_id',
          name: 'Тип клиента',
        },
        {
          code: 'user.name',
          name: 'Ответственный',
        },
        // {
        //   code: 'courier',
        //   name: 'Курьер',
        // },
        {
          code: 'delivery_type_id',
          name: 'Тип доставки',
        },
        {
          code: 'date',
          name: 'Дата доставки',
        },
        {
          code: 'delivery_time',
          name: 'Время доставки',
        },
        {
          code: 'times_of_day',
          name: 'Время суток',
        },
        {
          code: 'created_at',
          name: 'Дата создания заказа',
        },
        {
          code: 'user.name',
          name: 'Создатель заказа',
        },
        {
          code: 'order_status.name',
          name: 'Статус заказа',
        },
        {
          code: 'order_source_ids',
          name: 'Т/С',
        },
        {
          code: 'id',
          name: 'Номер заказа',
        },
        {
          code: 'cost',
          name: 'Сумма заказа',
        },
        {
          code: 'total_cost_comment',
          name: 'Комментарий к сумме',
        },
        {
          code: 'delivery_cost',
          name: 'Стоимость доствки',
        },
        {
          code: 'pre_payment',
          name: 'Предоплата',
        },
        {
          code: 'already_paid',
          name: 'Оплачен',
        },
        {
          code: 'addressee_name',
          name: 'Имя получателя',
        },
        {
          code: 'addressee_phone',
          name: 'Телефон получателя',
        },
        {
          code: 'address',
          name: 'Адрес',
        },
        {
          code: 'entrance',
          name: 'Подъезд',
        },
        {
          code: 'flat',
          name: 'Квартира',
        },
        {
          code: 'floor',
          name: 'Этаж',
        },
        {
          code: 'incognito',
          name: 'Инкогнито',
        },
        {
          code: 'comment',
          name: 'Комментарий',
        },
        {
          code: 'bouquets',
          name: 'Букеты',
        },
        {
          code: 'is_phone_call',
          name: 'Звонить предварительно',
        },
        {
          code: 'is_photo',
          name: 'Фото по готовности',
        },
        {
          code: 'is_photo',
          name: 'Фото по готовности',
        },
        {
          code: 'courier.name',
          name: 'Имя курьер',
        },
        {
          code: 'bit_order_id',
          name: 'B24 ID',
        },
      ],
      sortOrder: [
        {
          name: 'Нет',
          code: '',
        },
        {
          name: '↑',
          code: 'asc',
        },
        {
          name: '↓',
          code: 'desc',
        },
      ],
      createdSuccess: false,
    };
  },
  computed: {
    userSettings() {
      const settings = this.$store.getters.getAuthUserSettings;
      return (settings) ? JSON.parse(settings) : [];
    },
  },
  methods: {
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$router.push('/orders/');
    },
    addCol() {
      const newCol = {
        id: this.settings.orderSettings.length + 1,
        columnName: '',
        sortField: '',
        sortOrder: '',
        width: 100,
        dataFields: [],
      };

      this.settings.orderSettings.push(newCol);
    },
    delCol(index) {
      this.settings.orderSettings.splice(index, 1);
    },
    addProp(index) {
      const newProp = {
        id: this.settings.orderSettings.length + 1,
        field: '',
        displayName: '',
      };

      this.settings.orderSettings[index].dataFields.push(newProp);
    },
    delProp(colIndex, propIndex) {
      this.settings.orderSettings[colIndex].dataFields.splice(propIndex, 1);
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        // const propsItem = Object.assign({}, this.editedItem);
        // delete propsItem.id;

        const settings = JSON.stringify(this.settings.orderSettings);

        const propsItem = {
          'user_setting': settings,
        };
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users/${this.$store.getters.getAuthUser}`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;
            this.$store.commit('setAuthUserSettings', settings);
            localStorage.setItem('user-settings', settings);

            setTimeout(() => {
              this.$router.push('/orders/');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.settings.orderSettings = this.userSettings;
    this.settings.orderSettings.forEach((col, colIndex) => {
            col.dataFields.forEach((prop, propIndex) => {
                console.log("prop",prop.field);
            });
        });
  },
};
</script>

<style lang="scss">
  .order.v-card {
    position: relative;
  }
  .order .v-card__text {
    padding-bottom: 52px;
  }
  .order .v-card__actions {
    position: fixed;
    width: 100%;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 5px;
  }
</style>
