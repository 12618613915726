<template>
  <v-card>
    <v-alert
        :value="createdSuccess"
        type="success"
        class="my-0"
      >Заказ изменен</v-alert>
    <v-card-title
      class="px-2 py-1"
    >
      <span class="headline" style="margin: 0 auto;">Заказ №{{ id }}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text
      class="px-2 pt-3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <p class="mb-0">Букеты</p>
        <v-layout
          row
          wrap
          v-for="(bouquet, index) in editedItem.bouquets"
          :key="'bouquet-' + index"
          align-center
        >
          <v-flex
            
            class="pr-3"
          >
            <v-textarea
              label="Название"
              :rules="[v => !!v || 'Заполните поле']"
              auto-grow
              v-model="bouquet.name"
              row-height="6"
              hide-details
              class="mb-4"
              readonly
            ></v-textarea>
          </v-flex>
          <v-flex
            xs1
            class="pr-3"
          >
            <v-text-field
              label="Кол-во"
              type="number"
              v-model.number="bouquet.count"
              hide-details
              class="mb-4"
              style="width: 30px;"
            ></v-text-field>
          </v-flex>
          <v-flex
            xs4
            class="pr-3"
          >
            <v-text-field
              label="Место"
              hide-details
              class="mb-4"
              v-model="bouquet.place"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card-text>
    <v-card-actions
      class="px-4 py-2"
    >
      <v-btn
        @click="cancel()"
      >Отмена</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="info"
        @click="submitForm()"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editedItem: {},
      createdSuccess: false,
    };
  },
  methods: {
    getItem() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders/${this.id}`;

      axios
        .get(url)
        .then((response) => {
          const item = response.data;

          item.order_status = item.status;
          item.client_type = (item.client) ? item.client.client_type : 'individual';
          item.order_source_type = item.order_source;
          item.date = item.date.substr(0, 10);

          delete item.id;
          delete item.status;
          delete item.order_source;

          this.editedItem = item;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        const propsItem = Object.assign({}, this.editedItem);
        console.log('propsItem', propsItem)
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders/${this.id}`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getItem();
  },
};
</script>
