<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Статус изменен</v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4 py-2"
      >
        <span class="headline">Изменение статуса</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4 py-1"
      >
        <v-select
          label="Статус"
          :items="statusList"
          :rules="[v => !!v || 'Заполните поле']"
          item-text="name"
          item-value="id"
          v-model="editedItem.order_status_id"
          hide-details
          class="mb-4"
          @change="changeOrderStatus"
        ></v-select>

        <v-select
          label="Курьер"
          :items="couriersList"
          :rules="[v => !!v || 'Заполните поле']"
          item-text="name"
          item-value="id"
          v-model.number="editedItem.courier_id"
          no-data-text="Не надено"
          hide-details
          class="mb-4"
          v-if="editedItem.order_status_id === 3"
        ></v-select>
      </v-card-text>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editedItem: {
        order_ids: this.ids,
        order_status_id: 0,
        courier_id: null,
      },
      delivery_type_id:0,
      createdSuccess: false,
      statusList: [],
      couriersList: [],
    };
  },
  methods: {
    changeOrderStatus(){
      console.log('Selected order_status_id:', this.editedItem.order_status_id);
    
      // Check if the condition is met
      if (this.editedItem.order_status_id === 3 && this.editedItem.delivery_type_id === 2) {
        // Perform additional actions or log messages here
        console.log('Additional actions for order_status_id === 3 and delivery_type_id === 2');
      }
    },
    getStatus() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-status`;

      axios
        .get(url)
        .then((response) => {
          this.statusList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCouriers() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users`;

      axios
        .get(url, {
          params: {
            group_id: 15,
          },
        })
        .then((response) => {
          this.couriersList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        if(this.editedItem.order_status_id == 3 || this.editedItem.order_status_id == 4 || this.editedItem.order_status_id == 6){
          // this.editedItem.courier_id = null
        } else {
          // this.editedItem.courier_id = null
        }
        const propsItem = Object.assign({}, this.editedItem);
        // const url = `orders/${this.id}`;
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/multi-orders-status`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getStatus();
    this.getCouriers();
  },
};
</script>
