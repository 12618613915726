<template>
  <v-container fluid class="pa-0">
    <v-dialog :value="loadingDialog" persistent max-width="320px">
      <v-list>
        <v-list-tile
          v-for="(item, index) in loadingData"
          :key="index"
          avatar
          :color="item.error ? 'red' : item.color"
        >
          <v-list-tile-avatar>
            <v-progress-circular
              :value="100"
              :size="30"
              :color="item.error ? 'red' : item.color"
              :indeterminate="item.loading"
            ></v-progress-circular>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-dialog>
    <template v-if="!loadingDialog">
      <v-snackbar
        :value="true"
        v-if="selectedOrders.length > 0"
        color="info"
        bottom
        :timeout="3600000"
      >
        <v-btn
          dark
          flat
          depressed
          :to="`/print/orders/delivery/${printOrdersIds}/`"
          target="_blank"
          class="ml-0"
          >Печать бланков на доставку</v-btn
        >

        <v-btn
          dark
          flat
          depressed
          :to="`/print/orders/florist/${printOrdersIds}/`"
          target="_blank"
          >Печать бланков флористов</v-btn
        >
        <v-btn
          color="info"
          @click="multiOrderStatus(printOrdersIds)"
        >Multi Order Status</v-btn>
      </v-snackbar>
      <v-card>
        <v-card-title>
          <v-btn to="/orders/add/" color="primary" dark class="mb-2"
            >Добавить</v-btn
          >

          <v-dialog
            :value="dialogForm"
            persistent
            :max-width="editedIds || editStatus || editDescription ? '420px' : '1200px'"
            :fullscreen="editedIds || editStatus || editDescription ? false : true"
          >
            <template v-if="dialogForm">
              <change-status
                v-if="editStatus"
                :id="editedId"
                :orderSourceType="orderSourceTypeEditElem"
                @cancel="closeDialog()"
              ></change-status>
              <multi-change-status
                v-if="multiChangelDialog"
                :ids="editedIds"
                @cancel="closeDialog()"
              ></multi-change-status>
              <change-description
                v-if="editDescription"
                :id="editedId"
                :orderSourceType="orderSourceTypeEditElem"
                @cancel="closeDialog()"
              ></change-description>
              <edit-bouquets
                v-if="editOrderBouquets"
                :id="editedId"
                :orderSourceType="orderSourceTypeEditElem"
                @cancel="closeDialog()"
              ></edit-bouquets>
              <order-edit
                v-if="editedId && !editStatus && !editDescription && !editOrderBouquets"
                :id="editedId"
                :copy="copyElem"
                :orderSourceType="orderSourceTypeEditElem"
                @cancel="closeDialog()"
              ></order-edit>
              <order-add
                v-if="
                  !editedIds && !editedId && !editStatus && !editDescription && !editSettings && !editOrderBouquets
                "
                @cancel="closeDialog()"
              ></order-add>
              <user-settings
                v-if="editSettings"
                :userSettings="userSettings"
                @cancel="closeDialog()"
              ></user-settings>
            </template>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-layout row wrap justify-space-around>
            <v-flex xs2 class="px-2">
              <v-select
                  label="Статус"
                  :items="[...[{name: 'Все', id: 0}], ...statusList]"
                  item-text="name"
                  item-value="id"
                  v-model="order_status_ids_arr"
                  multiple
                  hide-details
                  @change="customFilter()"
              ></v-select>
            </v-flex>
            <v-flex xs2 class="px-2">
              <v-autocomplete
                label="Клиент"
                :items="clientsList"
                item-text="name"
                item-value="id"
                v-model.number="filter.client_id"
                hide-details
                no-data-text="Не надено"
                clearable
                :search-input.sync="searchClients"
                @change="customFilter()"
              ></v-autocomplete>
            </v-flex>
            <v-flex
              xs2
              class="px-2"
              v-if="$store.getters.getAuthUserGroup.code === 'admin'"
            >
              <v-select
                label="Менеджер"
                :items="usersList"
                item-text="name"
                item-value="id"
                v-model="filter.user_id"
                hide-details
                clearable
                no-data-text="Не надено"
                @change="customFilter()"
              ></v-select>
            </v-flex>

            <v-flex xs2 class="px-2">
              <v-select
                label="Время суток"
                :items="deliveryTimeOfDayFilter"
                item-text="name"
                item-value="id"
                v-model="filter.times_of_day"
                hide-details
                @change="customFilter()"
              ></v-select>
            </v-flex>
            <v-flex xs2 class="pl-2">
              <v-menu
                :close-on-content-click="false"
                v-model="dataStartPicker"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
                class="mb-4"
              >
                <v-text-field
                  slot="activator"
                  label="Дата доставки (с)"
                  v-model="filter.start_date"
                  prepend-icon="event"
                  hide-details
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="filter.start_date"
                  @input="dataStartPicker = false"
                  no-title
                  scrollable
                  locale="ru-ru"
                  first-day-of-week="1"
                  :max="!!filter.end_date ? filter.end_date : undefined"
                  @change="customFilter()"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 class="pl-2">
              <v-menu
                :close-on-content-click="false"
                v-model="dataEndPicker"
                :nudge-right="40"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
                class="mb-4"
              >
                <v-text-field
                  slot="activator"
                  label="Дата доставки (по)"
                  v-model="filter.end_date"
                  prepend-icon="event"
                  hide-details
                  readonly
                ></v-text-field>
                <v-date-picker
                  v-model="filter.end_date"
                  @input="dataEndPicker = false"
                  no-title
                  locale="ru-ru"
                  scrollable
                  first-day-of-week="1"
                  :min="!!filter.start_date ? filter.start_date : undefined"
                  @change="customFilter()"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs2 class="px-2">
              <v-select
                v-if="shouldDisplayStoreSelect"
                label="Магазин"
                :items="storeList"
                item-text="name"
                item-value="id"
                v-model="filter.store_id"
                hide-details
                clearable
                no-data-text="Не надено"
                @change="customFilter()"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            :to="`/print/order/day-orders/${dateNowStr}/`"
            target="_blank"
            >Заказы за день</v-btn
          >
        </v-card-title>

        <v-layout row wrap align-center>
          <v-flex xs3 class="px-1">
            <v-layout row wrap align-center>
              <v-flex class="px-1">
                Доставок на сегодня: {{ todayDeliveriesCount }}<br>
                Количество заказов: {{ ordersCount }}
              </v-flex>
              <v-flex xs4 class="px-1">
                <v-btn color="primary" dark to="/orders/settings/">Настройки</v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs9 class="px-1 text-xs-right">
            <v-btn color="primary" dark @click.prevent="setb24Sync()"
              >B24 Sync</v-btn
            >
            <v-btn color="primary" dark @click.prevent="setFilterNewOrderSite()"
              >Новые заказы с сайта</v-btn
            >

            <v-btn color="primary" dark @click.prevent="setFilter14February()"
              >6 марта</v-btn
            >

            <v-btn color="primary" dark @click.prevent="setFilter8March()"
              >8 марта</v-btn
            >

            <v-btn color="primary" dark @click.prevent="setFilterDateNow()"
              >За сегодня</v-btn
            >

            <v-btn color="primary" dark @click.prevent="setFilterDateWeek()"
              >За неделю</v-btn
            >

            <v-btn color="primary" dark @click.prevent="setFilterDateMonth()"
              >За месяц</v-btn
            >
          </v-flex>
        </v-layout>

        <v-data-table
          :headers="headersTable"
          :items="ordersList"
          hide-actions
          no-data-text="Заказов не найдено"
          no-results-text="Заказов не найдено"
          :pagination.sync="pagination"
          class="orders-table"
          select-all
          v-model="selectedOrders"
        >
          <template slot="headers" slot-scope="props">
            <OrderTableRowHead
              :props="props"
              :userSort = "userSort"
              :change-sort="changeSort"
              :pagination="pagination"
              @select="selectedOrders = (selectedOrders.length > 0) ? [] : ordersList.slice()"
            ></OrderTableRowHead>
          </template>
          <template slot="items" slot-scope="props">
            <OrderTableRow
              :order="props.item"
              :selected="props.selected"
              :typeClient="typeClient"
              :deliveryList="deliveryList"
              :tsList="tsList"
              :storeList="storeList"
              @select="props.selected = $event"
            ></OrderTableRow>
          </template>
        </v-data-table>
        <v-layout row wrap justify-space-around class="py-2">
          <v-flex xs2 class="px-3">
            <v-text-field
              label="Количество на странице"
              v-model.number="take"
              hide-details
              @change="changeShowElem()"
            ></v-text-field>
          </v-flex>
          <v-flex xs10 class="text-xs-right px-3">
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="page === 1"
              @click="prevPage()"
            >
              <v-icon dark>keyboard_arrow_left</v-icon>
            </v-btn>
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="ordersList.length < take"
              @click="nextPage()"
            >
              <v-icon dark>keyboard_arrow_right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
      <v-btn fab color="info" class="mx-4" to="/orders/add/">
        <v-icon dark>add</v-icon>
      </v-btn>
    </template>
  </v-container>
</template>

<script>
import axios from 'axios';
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import parse from "date-fns/parse";
import OrderTableRow from "@/components/OrderTableRow.vue";
import OrderTableRowHead from "@/components/OrderTableRowHead.vue";
import ChangeStatus from "./changeStatus.vue";
import MultiChangeStatus from "./multiChangeStatus.vue";
import changeDescription from "./changeDescription.vue";
import changeAlreadyPaid from "./changeAlreadyPaid.vue";
import userSettings from "./userSettings.vue";
import editBouquets from './editOrderBouquets.vue';
// import { RecycleScroller } from 'vue-virtual-scroller'
// import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'


export default {
  name: 'Orders',
  components: {
    OrderTableRow,
    OrderTableRowHead,
    ChangeStatus,
    changeDescription,
    changeAlreadyPaid,
    userSettings,
    editBouquets,
    MultiChangeStatus,
    // RecycleScroller,
  },
  data() {
    return {
      filter: {
        order_status_id: '',
        client_id: null,
        user_id: null,
        store_id:0,
        times_of_day: '',
        start_date: '',
        end_date: '',
        order_status_ids:'',
      },
      order_status_ids_arr: [],
      searchClients: '',
      timerClients: null,
      loadingData: [
        {
          title: 'Получение заказов',
          error: false,
          loading: false,
          color: 'amber',
          id: 'orders',
        },
        {
          title: "Получение менеджеров",
          error: false,
          loading: true,
          color: "cyan",
          id: "managers"
        },
      ],
      adminRole:'',
      dateNowStr: '',
      dataStartPicker: false,
      dataEndPicker: false,
      search: '',
      ordersList: [],
      statusList: [],
      clientsList: [],
      usersList: [],
      copyElem: false,
      editSettings: false,
      multiChangelDialog: false,
      dataNowStr: '',
      todayDeliveriesCount: 0,
      ordersCount: 0,
      deliveryPrinted: [],
      floristPrinted: [],
      selectedOrders: [],
      deliveryTimeOfDayList: {
        1: "Утро",
        2: "День",
        3: "Вечер"
      },
      userSettings: [],
      pagination: {
        sortBy: "id",
        rowsPerPage: -1,
        descending: true
      },
      userSort: {},
      userFilter: {},
      deliveryTimeOfDayFilter: [
        {
          name: 'Все',
          id: '',
        },
        {
          name: 'Утро',
          id: 'morning',
        },
        {
          name: 'День',
          id: 'noon',
        },
        {
          name: 'Вечер',
          id: 'evening',
        },
      ],
      take: 20,
      page: 1,
      tableLoading: false,
      skipQuery: false,
      orderSourceTypeIds: [],
      clientSuggestions: [],
      skipClientsQuery: true,
      clientsQueryName: "",
      couriersList: [],
      editedIds: [],
      headersTable: [
        {
          text: '№',
          align: 'left',
          value: 'id',
          sortable: false,
        },
        {
          text: 'Заказчик',
          align: 'left',
          value: 'id',
          sortable: false,
        },
        {
          text: 'Состав',
          align: 'left',
          value: 'id',
          sortable: false,
        },
        {
          text: 'Адрес',
          align: 'left',
          value: 'id',
          sortable: false,
        },
        {
          text: 'Оплата',
          align: 'left',
          value: 'id',
          sortable: false,
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'action',
        },
      ],

      typeClient: [],
      deliveryList: [],
      tsList: [],
      storeList: [],
      bank_order_id: null,
    };
  },
  watch: {
    // dialogForm(newValue) {
    //   if (!newValue) {
    //     this.closeDialog();
    //   }
    // },
    updateOrderList(newValue) {
      if (newValue) {
        this.$store.commit("setUpdateOrderList", false);
        this.getOrdersList(false);
      }
    },
    searchClients(val) {
      const findClient = this.clientsList.find((item) => item.name === val);
      if (findClient) return false;

      if (val && val.length >= 3) {
        if (this.timerClients) clearTimeout(this.timerClients);

        this.timerClients = setTimeout(() => {
          this.getClients(val);
        }, 500);
      } else {
        this.clientsList = [];
      }
    },
    filter: {
      handler: function (val) {
        const filter = {
          start_date: val.start_date,
          end_date: val.end_date,
          client_id: val.client_id,
          user_id: val.user_id,
          times_of_day: val.times_of_day,
          order_status_ids_arr: this.order_status_ids_arr
        };
        //this.setUserFilter(JSON.stringify(filter));
        //localStorage.setItem("filter", JSON.stringify(filter));
        // console.log("filter", filter)
      },
      deep: true,
    },
    pagination: {
      handler() {
        this.page = 1;

        this.getOrdersList();
      },
      deep: true,
    },
  },
  created() {
    // this.getUserFilter();
  },
  computed: {
    shouldDisplayStoreSelect() {
      // Check conditions for displaying store select
      return (
        (parseInt(localStorage.getItem('store-id')) == 0 && this.adminRole == 'admin')
      );
    },
    updateOrderList() {
      return this.$store.getters.getUpdateOrderList;
    },
    loadingDialog() {
      const loadData = this.loadingData.filter(
        item => !item.error && !item.loading
      );
      return loadData.length === this.loadingData.length ? 0 : 1;
    },
    orderSourceTypeEditElem() {
      const editElem = this.ordersList.find(item => item.id === this.editedId);
      return editElem ? editElem.orderSourceTypeIds : [];
    },
    // headersTable() {
    //   const cols = this.userSettings.map(item => {
    //     const elem = {
    //       text: item.columnName,
    //       align: "left",
    //       value: item.sortField,
    //       width: item.width
    //     };

    //     return elem;
    //   });

    //   const colAction = {
    //     text: "",
    //     align: "right",
    //     sortable: false,
    //     value: "action"
    //   };

    //   cols.push(colAction);

    //   return cols;
    // },
    printOrdersIds() {
      return this.selectedOrders.map(item => item.id);
    },

    editedId() {
      return this.$store.getters.getEditedOrderId;
    },
    editStatus() {
      return this.$store.getters.getEditStatus;
    },
    editDescription() {
      return this.$store.getters.getEditDescription;
    },
    editOrderBouquets() {
      return this.$store.getters.getEditOrderBouquets;
    },
    dialogForm() {
      return this.$store.getters.getShowDialogOrderEdit;
    },
  },
  methods: {
    getUserSort() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users/${this.$store.getters.getAuthUser}`;
      console.log("url", process.env.VUE_APP_DEV_SERVER_PROXY)
      axios
        .get(url)
        .then((response) => {
          this.userSort = JSON.parse(response.data.user_sort);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserFilter() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users/${this.$store.getters.getAuthUser}`;
      axios
        .get(url)
        .then((response) => {
          this.userFilter = JSON.parse(response.data.user_filter);
          
          if (this.userFilter) {
            this.filter = {
              ...this.filter,
              ...this.userFilter,
            };
            this.order_status_ids_arr = this.userFilter.order_status_ids_arr || [];
            if (this.filter.client_id) {
              this.getClient();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStatus() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-status`;

      axios
        .get(url)
        .then((response) => {
          this.statusList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClientTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/client-types`;

      axios
        .get(url)
        .then((response) => {
          this.typeClient = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStoreList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;

      axios
        .get(url)
        .then((response) => {
          this.storeList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDeliveryTypes() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-delivery-types`;

      axios
        .get(url)
        .then((response) => {
          this.deliveryList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOrdersSource() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-source`;

      axios
        .get(url)
        .then((response) => {
          this.tsList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCouriers() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users`;

      axios
        .get(url, {
          params: {
            group_id: 15,
          },
        })
        .then((response) => {
          this.couriersList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClients(searchVal) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients`;

      axios
        .get(url, {
          params: {
            name_or_phone: searchVal,
            page_limit: 999,
          },
        })
        .then((response) => {
          this.clientsList = response.data.map((item) => {
            return {
              name: `${item.name} (${item.phone})`,
              id: item.id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getClient() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients/${this.filter.client_id}`;

      axios
        .get(url)
        .then((response) => {
          const item = response.data;

          if (item) {
            this.clientsList = [
              {
                name: `${item.name} (${item.phone})`,
                id: item.id,
              },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUsers() {
      const loadData = this.loadingData.find(item => item.id === 'managers');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users`;

      axios
        .get(url, {
          params: { group_id: 14}
        })
        .then((response) => {
          const items = response.data;
          this.usersList = items;

          loadData.title = 'Менеджеры получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения менеджеров!';
          loadData.error = true;
          console.log(error);
        });
    },
    getTodayDeliveriesCount() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-count`;

      const propsItem = {
        start_date: this.dateNowStr,
        end_date: this.dateNowStr,
        delivery_type_id: 2,
      };

      axios
        .get(url, {
          params: propsItem,
        })
        .then((response) => {
          this.todayDeliveriesCount = response.data.order_count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStoreList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;

      axios
        .get(url)
        .then((response) => {
          this.storeList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOrdersCount() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-count`;
      const propsItem = {};

      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key]) {
          propsItem[key] = this.filter[key];
        }
      });

      axios
        .get(url, {
          params: propsItem,
        })
        .then((response) => {
          this.ordersCount = response.data.order_count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setUserSort(sort){
      const propsItem = {
        'user_sort': sort,
      };
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users/${this.$store.getters.getAuthUser}`;

      axios
        .post(url, propsItem)
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setUserFilter(filter){
      const propsItem = {
        'user_filter': filter,
      };
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users/${this.$store.getters.getAuthUser}`;

      axios
        .post(url, propsItem)
        .then(() => {
          
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getOrdersList() {
      const loadData = this.loadingData.find(item => item.id === 'orders');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders`;

      const propsItem = {
        page: this.page,
        page_limit: this.take,
      };

      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key]) {
          propsItem[key] = this.filter[key];
        }
      });

      // get local sort filter
      const sortLocal = localStorage.getItem('orders-sort');
      const sortFilter = (sortLocal) ? JSON.parse(sortLocal) : {};
      if(sortFilter) {
        this.pagination.sortBy = sortFilter.sortBy
      }

      if (this.pagination.sortBy) {
        const sort = {};
        sort[this.pagination.sortBy] = (this.pagination.descending) ? 'desc_nulls_last' : 'asc_nulls_last';
        propsItem.order_by = JSON.stringify(sort);
      }
      axios
        .get(url, {
          params: propsItem,
        })
        .then((response) => {
          const items = response.data;
          this.ordersList = items;

          loadData.title = 'Заказы получены!';
          loadData.loading = false;
          // add balck line 
          if(this.pagination.sortBy && this.pagination.sortBy == "date"){
            const ordersCount = { aggregate: { count: this.getOrdersCount() || 0 } };
            this.update({ ordersList: items, ordersCount  });
          }
          this.getOrdersCount();
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения заказов!';
          loadData.error = true;
          console.log(error);
        });
    },
    formatDate(date, dateFormat) {
      return format(new Date(date), dateFormat, { locale: ru });
    },
    update({ ordersList, ordersCount }) {
      const parseDateFormat = "yyyy-MM-dd";
      let prevItem = null;
      // Ensure ordersList is defined and is an array
      if (!Array.isArray(ordersList)) {
        console.error('ordersList is not an array:', ordersList);
        return [];
      }

      
      for (let order of ordersList) {
        if (!order.date) {
            console.error('order.date is null or undefined:', order);
            continue;
        }

        const currentDateStr = order.date;

        const currentDate = new Date(format(new Date(currentDateStr), parseDateFormat));

        if (isNaN(currentDate.getTime())) {
            console.error('Invalid date format:', currentDateStr);
            continue;
        }
        
        if (prevItem) {
          const previousDate = new Date(format(new Date(prevItem), parseDateFormat));
          order.isTopLine = isAfter(currentDate, previousDate);

        } else {
          order.isTopLine = false;
        }

        prevItem = currentDateStr;
    }
      // this.ordersCount = ordersCount;
      

      return ordersList;
    },
    onClientSelect(item) {
      this.filter.clientItem = item;
    },
    onClientsInputChange(text) {
      this.clientsQueryName = `%${text}%`;
      this.skipClientsQuery = false;

      if (text === "") {
        this.filter.clientItem = null;
      }
    },
    printOrders(type) {
      const arId = this.selectedOrders.map(item => item.id);
      if (type === "delivery") {
        this.$router.push({ name: "ordersDelivery", params: { ids: arId } });
      } else if (type === "florist") {
        this.$router.push({ name: "ordersFlorist", params: { ids: arId } });
      }
    },
    printDoc(id, type) {
      const { protocol, hostname } = window.location;
      const url = `${protocol}//${hostname}/print/order/${id}/${type}`;
      window.open(url, "_blank");
    },
    printDayOrders() {
      const dateNow = new Date();
      const dateNowStr = dateNow.toISOString().split("T")[0];
      const { protocol, hostname } = window.location;
      const url = `${protocol}//${hostname}/print/day-orders/${dateNowStr}`;
      window.open(url, "_blank");
    },
    customFilter() {
      this.page = 1;
      this.filter.order_status_ids = this.order_status_ids_arr.join(',');
      // console.log('start_date', this.filter.start_date)
      localStorage.setItem('start_date', this.filter.start_date)
      localStorage.setItem('end_date', this.filter.end_date)
      this.getOrdersList();
    },
    changeShowElem() {
      localStorage.setItem("countElemPage", this.take);
      this.$store.commit("setCountElemPage", this.take);
      this.page = 1;
      this.getOrdersList();
    },
    prevPage() {
      this.page -= 1;
      this.getOrdersList();
    },
    nextPage() {
      this.page += 1;
      this.getOrdersList();
    },
    clientsFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.phone.replace(/[^0-9]/gim, "");
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    closeDialog() {
      this.getOrdersList();
      this.$store.commit('setShowDialogOrderEdit', false);
      this.copyElem = false;
      this.$store.commit('setEditedOrderId', 0);
      setTimeout(() => {
        this.$store.commit('setEditStatus', false);
        this.$store.commit('setEditDescription', false);
        this.$store.commit('setEditOrderBouquets', false);
        this.editSettings = false;
        this.multiChangelDialog = false;
        this.editedIds = [];
      }, 300);
    },
    editItem(id, copy = false) {
      this.$store.commit('setEditedOrderId', +id);
      this.copyElem = copy;
      this.$store.commit('setShowDialogOrderEdit', true);
    },
    changeStatus(id) {
      this.$store.commit('setEditedOrderId', +id);
      this.$store.commit('setEditStatus', true);
      this.$store.commit('setShowDialogOrderEdit', true);
    },
    multiOrderStatus(ids){
      this.editedIds = ids;
      this.multiChangelDialog = true;
      this.$store.commit('setShowDialogOrderEdit', true);
    },
    changeDescription(id) {
      this.$store.commit('setEditedOrderId', +id);
      this.$store.commit('setEditDescription', true);
      this.$store.commit('setShowDialogOrderEdit', true);
    },
    changeBouquets(id) {
      this.$store.commit('setEditedOrderId', +id);
      this.$store.commit('setEditOrderBouquets', true);
      this.$store.commit('setShowDialogOrderEdit', true);
    },
    changeSettings() {
      this.editSettings = true;
      this.$store.commit('setShowDialogOrderEdit', true);
    },
    createdBouquet: function createdBouquet(item) {
      let cardsList = JSON.parse(localStorage.getItem("cardsList"));
      cardsList = cardsList !== null ? cardsList : [];

      const index = cardsList.findIndex(card => card.props.order === item.id);
      if (index === -1) {
        cardsList.push({
          sum: 0,
          success: false,
          props: {
            floristId: null,
            orderId: item.id,
            client: item.client,
            clientId: item.client.id,
            decorPercent: 25,
            deliveryCost: item.deliveryCost,
            salePercent: '',
            sumDecorAdditional: 0
          },
          goods: []
        });
      } else {
        cardsList[index].props.clientId = item.client.id;
      }
      localStorage.setItem("cardsList", JSON.stringify(cardsList));
      const routeData = this.$router.resolve({ path: "/", query: { selectOrder: item.id } });
      window.open(routeData.href, '_blank');
    },
    getWeekEndDate() {
      const date = new Date();
      let diff = 7 - date.getDay();
      if (diff < 0) {
        diff += 6;
      }
      date.setDate(date.getDate() + 1 * diff);
      return date.toISOString().split("T")[0];
    },
    getWeekStartDate() {
      const date = new Date();
      let diff = date.getDay() - 8;
      if (diff < 0) {
        diff += 7;
      }
      date.setDate(date.getDate() + -1 * diff);
      return date.toISOString().split("T")[0];
    },
    setFilterDateNow() {
      this.filter.start_date = this.dateNowStr;
      this.filter.end_date = this.dateNowStr;
      localStorage.setItem('start_date', this.filter.start_date)
      localStorage.setItem('end_date', this.filter.end_date)
      this.customFilter();
    },
    // handleStatusChange(selectedStatuses) {
    //   console.log(selectedStatuses)
    //   if (selectedStatuses.includes(0)) {
    //     this.order_status_ids_arr = [0];
    //   } else {
    //     this.order_status_ids_arr = selectedStatuses.filter(id => id !== 0);
    //   }
    //   this.customFilter();
    // },
    setb24Sync(){
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/bit-order-create`
      axios
        .post(url)
        .then((res) => {
          console.log(res)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setFilterDateWeek() {
      this.filter.start_date = this.getWeekStartDate();
      this.filter.end_date = this.getWeekEndDate();
      localStorage.setItem('start_date', this.filter.start_date)
      localStorage.setItem('end_date', this.filter.end_date)
      this.customFilter();
    },
    setFilterDateMonth() {
      const date = new Date();
      const firstDay = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        1,
        date.getUTCHours()
      );
      const lastDay = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth() + 1,
        0,
        23,
        59,
        59,
      );
      const dateStart = firstDay.toISOString().split("T")[0];
      const dateEnd = lastDay.toISOString().split("T")[0];

      this.filter.start_date = dateStart;
      this.filter.end_date = dateEnd;
      localStorage.setItem('start_date', this.filter.start_date)
      localStorage.setItem('end_date', this.filter.end_date)
      this.customFilter();
    },
    setFilter14February() {
      const date = new Date();
      this.filter.start_date = `${date.getFullYear()}-03-06`;
      this.filter.end_date = `${date.getFullYear()}-03-06`;
      localStorage.setItem('start_date', this.filter.start_date)
      localStorage.setItem('end_date', this.filter.end_date)
      this.customFilter();
    },
    setFilter8March() {
      const date = new Date();
      this.filter.start_date = `${date.getFullYear()}-03-08`;
      this.filter.end_date = `${date.getFullYear()}-03-08`;
      localStorage.setItem('start_date', this.filter.start_date)
      localStorage.setItem('end_date', this.filter.end_date)
      this.customFilter();
    },
    setFilterNewOrderSite() {
      this.filter.start_date = '';
      this.filter.end_date = '';
      localStorage.setItem('start_date', this.filter.start_date)
      localStorage.setItem('end_date', this.filter.end_date)
      // this.filter.order_status_id = 1;

      this.customFilter();
    },
    toggleAll() {
      if (this.selectedOrders.length) {
        this.selectedOrders = [];
      } else {
        this.selectedOrders = this.ordersList.slice();
      }
    },
    changeSort(column) {
      console.log("column",column)
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }

      const sort = {
        sortBy: this.pagination.sortBy,
        descending: this.pagination.descending
      };
      this.setUserSort(JSON.stringify(sort))
      this.getUserSort()
      // set orders sort by 
      localStorage.setItem('orders-sort', JSON.stringify(sort));
      this.$store.commit("setOrderSort", sort);
    },
    getBankStatus() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-bank-status`;

      const propsItem = {
        bank_order_id: this.bank_order_id,
      };
      axios
        .post(url, propsItem)
        .then((response) => {
          console.log('bank',response.data);
          const item = response.data
          if(item.payment_type_id && item.payment_type_id == 17){
           
            const props = {
              amount: parseInt(item.amount),
              client_id: item.client_id || 0,
              payment_type_id: item.payment_type_id,
              store_id: 1,
              bank_order_id: this.bank_order_id,
            }
            this.createPayment(props)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createPayment(props){
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments`;
          axios
            .post(url, props)
            .then((res) => {
              console.log(res)
            })
            .catch((error) => {
              console.log(error);
              
            });
    },
  },
  mounted() {
    // get store_id and admin role form logged in user
    const store_id = parseInt(localStorage.getItem('store-id'))
    this.filter.store_id = store_id
    const adminRole = JSON.parse(localStorage.getItem('user-group')).code
    this.adminRole = adminRole

    this.take = this.$store.getters.getCountElemPage;
    const dateNow = new Date();
    const year = dateNow.getFullYear();
    let month = dateNow.getMonth() + 1;
    month = `0${month}`.slice(-2);
    let day = dateNow.getDate();
    day = `0${day}`.slice(-2);

    this.dateNowStr = `${year}-${month}-${day}`;
    const startDate = localStorage.getItem('start_date')
    const endDate = localStorage.getItem('end_date')

    if (startDate) {
      this.filter.start_date = startDate
    } else {
      this.filter.start_date = this.dateNowStr;
      localStorage.setItem('start_date', this.dateNowStr)
    }

    if (endDate) {
      this.filter.end_date = endDate
    } else {
      this.filter.end_date = this.dateNowStr;
      localStorage.setItem('end_date', this.dateNowStr)
    }
    
    
    this.getStatus();
    this.getClientTypes();
    this.getDeliveryTypes();
    this.getOrdersSource();
    this.getCouriers();
    this.getOrdersList();
    this.getUsers();
    this.getStoreList();
    this.getUserSort();

    // if(this.$route.query.orderId){
    //   this.bank_order_id = this.$route.query.orderId;
    //   this.getBankStatus();
    // }
    if (this.$route.query.clientId) {
      this.filter.client_id = +this.$route.query.clientId;
      this.searchClients = this.$route.query.clientName;
      this.filter.start_date = ''
      this.filter.end_date = ''
    }
    this.getTodayDeliveriesCount();
  },
};
</script>

<style land="scss">
.orders-table .theme--light.v-table tbody tr:not(:last-child).top-line {
  border-top: 4px solid #404040 !important;
}
.orders-table .theme--light.v-table tbody tr:not(:last-child) {
  border-bottom: none;
  border-top: 1px solid #9c9c9c !important;
}
.orders-table table.v-table thead th:first-child,
.orders-table table.v-table thead th:not(:first-child) {
  padding: 0 4px;
}
.orders-table table.v-table tbody td:first-child,
.orders-table table.v-table tbody td:not(:first-child),
.orders-table table.v-table tbody th:first-child,
.orders-table table.v-table tbody th:not(:first-child),
.orders-table table.v-table thead td:first-child,
.orders-table table.v-table thead td:not(:first-child),
.orders-table table.v-table thead th:first-child,
.orders-table table.v-table thead th:not(:first-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.theme--light.v-table thead tr:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.orders-table .v-datatable thead th.column.sortable .v-icon {
  display: block;
  width: 16px;
}
.v-snack__wrapper {
  max-width: 100%;
}
</style>
