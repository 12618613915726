<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Комментарий изменен</v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4 py-2"
      >
        <span class="headline">Изменение комментария</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4 py-1"
      >
        <v-textarea
          label="Комментарий"
          auto-grow
          :rules="[v => !!v || 'Заполните поле']"
          v-model="editedItem.comment"
          row-height="12"
          hide-details
          class="mb-4"
        ></v-textarea>
      </v-card-text>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      editedItem: {
        order_id: 0,
        comment:'',
      },
      createdSuccess: false,
    };
  },
  methods: {
    getItem() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders/${this.id}`;

      axios
        .get(url)
        .then((response) => {
          const item = response.data;

          // item.order_status = item.status;
          // item.client_type = (item.client) ? item.client.client_type : 'individual';
          // item.order_source_type = item.order_source;
          // item.date = item.date.substr(0, 10);

          // delete item.id;
          // delete item.status;
          // delete item.order_source;

          this.editedItem.order_id = item.id;
          this.editedItem.comment = item.comment;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.editedItem = {};
      this.createdSuccess = false;
      this.$emit('cancel');
    },
    submitForm() {
      const validate = this.$refs.form.validate();
      if (validate) {
        const propsItem = Object.assign({}, this.editedItem);
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/orders-comment`;

        axios
          .post(url, propsItem)
          .then(() => {
            this.createdSuccess = true;

            setTimeout(() => {
              this.$emit('cancel');
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getItem();
  },
};
</script>
